import React from 'react';

export const PontemIcon = (props: React.ComponentProps<'svg'>) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384.000000 384.000000"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1="9.0686"
        y1="15.116"
        x2="30.0686"
        y2="15.1445"
        gradientTransform="matrix(1 0 0 -1 0 29.2593)"
      >
        <stop offset="8.580000e-02" style={{ stopColor: '#8D29C1' }} />
        <stop offset="0.2383" style={{ stopColor: '#942BBB' }} />
        <stop offset="0.4667" style={{ stopColor: '#A92FAC' }} />
        <stop offset="0.7413" style={{ stopColor: '#CA3793' }} />
        <stop offset={1} style={{ stopColor: '#F03F77' }} />
        <stop offset={1} style={{ stopColor: '#F03F77' }} />
      </linearGradient>
      <g
        transform="translate(0.000000,384.000000) scale(0.100000,-0.100000)"
        fill="url(#SVGID_1_)"
        stroke="none"
      >
        <path d="M1795 3834 c-845 -68 -1534 -644 -1735 -1454 -72 -289 -77 -550 -15 -856 80 -395 304 -778 614 -1046 591 -512 1408 -620 2106 -278 225 111 435 272 597 459 383 442 544 1012 447 1579 -112 656 -551 1205 -1164 1455 -130 53 -302 101 -437 121 -103 16 -329 26 -413 20z m251 -154 c522 -42 976 -292 1287 -706 174 -232 277 -474 327 -770 19 -113 22 -154 17 -329 -4 -151 -10 -224 -27 -299 -27 -126 -73 -271 -115 -363 l-34 -73 -85 39 c-189 88 -606 231 -669 231 -16 0 -17 58 -17 748 0 723 -1 748 -20 779 -13 22 -29 34 -52 38 -18 3 -367 5 -775 3 l-742 -3 -23 -23 -23 -23 -3 -759 -2 -759 -43 -11 c-113 -28 -428 -137 -561 -194 l-148 -64 -30 65 c-43 95 -94 253 -118 368 -31 143 -39 438 -16 593 121 820 788 1448 1604 1511 59 4 114 9 123 10 9 0 74 -4 145 -9z m33 -1123 c79 -40 125 -80 170 -147 65 -99 71 -142 71 -561 l0 -361 -37 6 c-21 3 -150 8 -288 12 l-250 5 -65 -96 c-36 -53 -68 -102 -72 -109 -5 -9 61 -12 280 -12 290 0 383 -7 598 -44 177 -31 177 -29 -5 -91 l-161 -54 0 -452 0 -451 -47 -10 c-180 -39 -515 -41 -683 -5 l-85 18 -3 995 c-2 712 0 1012 9 1055 27 146 141 275 282 321 27 9 76 13 137 11 80 -2 103 -7 149 -30z m-989 -1772 c0 -228 -4 -415 -8 -415 -14 0 -148 86 -229 147 -108 81 -256 230 -343 346 -40 54 -71 99 -69 102 11 10 190 88 291 126 109 42 320 108 346 109 9 0 12 -89 12 -415z m1820 362 c187 -59 480 -179 480 -197 0 -4 -28 -45 -62 -91 -79 -106 -229 -257 -335 -337 -79 -60 -240 -162 -255 -162 -5 0 -8 189 -8 420 l0 420 28 -6 c15 -4 83 -25 152 -47z" />
      </g>
    </svg>
  );
};

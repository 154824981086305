import React from 'react';

export const MSafeIcon = (props: React.ComponentProps<'svg'>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 247 170" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.593 23.013C107.499 26.0558 110.794 30.8785 140.912 79.1039C172.419 129.552 173.864 132.114 173.864 137.536C173.864 142.451 173.34 143.725 169.917 147.112C166.476 150.518 165.222 151.019 160.138 151.019C155.584 151.019 153.536 150.378 150.797 148.097C148.868 146.491 139.862 133.175 130.785 118.508C103.719 74.7741 90.061 53.1676 89.7267 53.5549C86.6685 57.1033 78.661 72.3656 78.661 74.6443C78.661 76.538 84.4463 87.3004 93.7205 102.658C102.003 116.374 109.594 129.524 110.589 131.881C114.464 141.062 107.754 151.019 97.6877 151.019C94.8409 151.019 91.2151 150.178 89.6303 149.151C88.0455 148.122 80.9795 137.78 73.9287 126.169C66.8778 114.556 60.6745 105.055 60.1428 105.055C59.611 105.055 53.6933 114.234 46.9931 125.452C40.2929 136.669 33.7262 146.815 32.4015 147.995C31.0767 149.176 27.7063 150.396 24.9129 150.709C15.8384 151.721 9 145.878 9 137.11C9 132.842 9.04876 132.752 43.5356 74.0295C57.8254 49.6973 71.2212 28.0793 73.3041 25.9891C81.0771 18.1879 92.88 16.9894 102.593 23.013ZM166.901 23.0233C170.308 26.4821 233.499 127.116 235.447 132.185C238.258 139.504 235.277 146.312 227.665 149.96C223.547 151.933 222.073 152.11 218.245 151.089C215.749 150.424 212.866 148.96 211.838 147.835C210.809 146.71 194.796 121.5 176.253 91.8118C140.054 33.857 140.044 33.8352 144.274 25.7398C148.263 18.1017 160.594 16.6228 166.901 23.0233Z"
        fill="#48B5A7"
      />
    </svg>
  );
};

import { Wallet } from '@aptos-labs/wallet-adapter-core';
import { LinkExternal } from '@antcorefinance/ui';
import { useNetwork } from 'lib/common/use-network';
import React, { SVGProps } from 'react';

interface NotFoundWalletList {
  Icons: Record<string, (props: SVGProps<SVGSVGElement>) => JSX.Element | null>;
  wallet: Wallet;
}

export const NotFoundWalletList = ({ Icons, wallet }: NotFoundWalletList) => {
  const {
    other: { MSafeOrigin },
  } = useNetwork();

  return (
    <LinkExternal
      href={
        wallet.name === 'MSafe'
          ? `${MSafeOrigin}apps/0?url=${encodeURIComponent(window.location.href)}`
          : wallet.url
      }
      className="relative flex w-full cursor-pointer items-center gap-4 rounded-xl p-0 px-4 py-3 hover:bg-black/[0.04] active:bg-black/[0.06] hover:dark:bg-white/[0.02] active:dark:bg-white/[0.03]"
      rel="noreferrer"
    >
      <span className="h-[18px] w-[18px]">
        {React.createElement(Icons[wallet.name], {
          width: 18,
          height: 18,
          strokeWidth: 2,
          className: 'text-blue-500',
        })}
      </span>
      <span className="text-sm font-medium text-gray-900 dark:text-slate-200">{wallet.name}</span>
    </LinkExternal>
  );
};

// app/actions.ts
'use server';
import prisma from '@/lib/prisma'; // Adjust the path based on your prisma setup
import axios from 'axios';

export async function myServerAction() {
  const now = new Date();
  const currentTimeInSeconds = Math.floor(now.getTime() / 1000);

  // Calculate seconds since the last 4-hour reset (e.g., 00:00, 04:00, 08:00, etc.)
  const secondsSinceLastReset = currentTimeInSeconds % (4 * 60 * 60);

  // Calculate remaining seconds until the next 4-hour reset
  const secondsLeft = 8 * 60 * 60 - secondsSinceLastReset;

  return { secondsLeft };
}

export async function fetchUserData(walletAddress: string) {
  if (!walletAddress) return { factionId: null, userPoints: 0 };

  // Fetch user data from Prisma
  const user = await prisma.user.findUnique({
    where: { walletAddress },
    select: {
      factionId: true,
      points: true,
    },
  });

  return {
    factionId: user?.factionId ?? null,
    userPoints: user?.points ?? 0,
  };
}

export async function getCompletedTasks(walletAddress: string) {
  if (!walletAddress) {
    throw new Error('Wallet address is required');
  }

  // Validate the user
  const existingUser = await prisma.user.findUnique({
    where: { walletAddress },
  });

  if (!existingUser) {
    return { error: 'User not found. Please complete your profile.' };
  }

  // Retrieve completed tasks for the user
  const completedTasks = await prisma.userTask.findMany({
    where: {
      userId: existingUser.id,
    },
    select: {
      taskId: true,
    },
    // cacheStrategy: { ttl: 60 },
  });

  // Extract task IDs
  const completedTaskIds = completedTasks.map((task) => task.taskId);

  // Revalidate the path (optional, based on where your data is used)
  // revalidatePath('/path-to-revalidate'); // Replace with the path you need to revalidate

  return completedTaskIds;
}

const telegramApi = process.env.TELEGRAM_TOKEN;
const telegramChatId = process.env.TELEGRAM_CHAT_ID;

export async function getOrCreateInviteLink(walletAddress: any) {
  try {
    // Check if the Ethereum address already exists
    const existingUser = await prisma.user.findUnique({
      where: { walletAddress },
    });

    // If the user exists and has an invite link, return it
    if (existingUser && existingUser.telegramInviteLink) {
      return {
        success: true,
        inviteLink: existingUser.telegramInviteLink,
        exists: true,
      };
    }

    // If the user exists but has no invite link or doesn't exist at all, create a new invite link
    const apiUrl = `https://api.telegram.org/bot${telegramApi}/createChatInviteLink`;
    const response = await axios.post(apiUrl, {
      chat_id: telegramChatId,
      member_limit: 1,
    });

    // Check if the invite link creation was successful
    if (response.data.ok) {
      const telegramInviteLink = response.data.result.invite_link;

      // If the user exists, update their record; otherwise, create a new user entry
      if (existingUser) {
        await prisma.user.update({
          where: { walletAddress },
          data: { telegramInviteLink },
        });
      } else {
        await prisma.user.create({
          data: { walletAddress, telegramInviteLink },
        });
      }

      return {
        success: true,
        inviteLink: telegramInviteLink,
        exists: false,
      };
    }

    // Handle failure in creating the invite link
    throw new Error('Error creating invite link');
  } catch (error: any) {
    console.error('Error:', error);
    return {
      success: false,
      error: error.message || 'An unknown error occurred',
    };
  }
}

export async function checkAndRegisterUser(walletAddress: string) {
  const user = await prisma.user.findUnique({
    where: { walletAddress },
    include: {
      tasks: true, // Fetch user's completed tasks
    },
  });

  if (!user) {
    // Log the error to an external logging service if needed
    return { error: 'User not found. Please complete your profile.' };
  }

  // Get the total number of tasks
  const totalTasks = await prisma.task.count();
  const completedTasks = user.tasks.length;

  // Check if the user has completed all tasks
  const tasksCompleted = completedTasks === totalTasks;

  if (!tasksCompleted) {
    return {
      error: `Please complete all ${totalTasks - completedTasks} remaining tasks to register.`,
    };
  }

  // Check if the user is already registered
  if (user.eventRegisteredAt) {
    return {
      isRegistered: true,
      tasksCompleted,
    };
  }

  // Register the user if all tasks are completed
  await prisma.user.update({
    where: { walletAddress },
    data: { eventRegisteredAt: new Date() },
  });

  return {
    success: true,
    isRegistered: true,
    tasksCompleted,
  };
}

export async function checkRegistrationStatus(walletAddress: string) {
  const user = await prisma.user.findUnique({
    where: { walletAddress },
  });

  return {
    isRegistered: !!user?.eventRegisteredAt,
  };
}

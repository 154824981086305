export const mainnet = {
  name: 'Antc Default Aptos List',
  timestamp: '2023-11-17T09:00:43.962Z',
  version: {
    major: 1,
    minor: 0,
    patch: 0,
  },
  tags: {},
  logoURI:
    'https://cdn.antc.com/image/upload/f_auto,c_limit,w_64,q_auto/tokens/137/0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a.jpg',
  keywords: ['antc', 'default', 'aptos'],
  schema: 'aptos',
  tokens: [
    {
      chainId: 1,
      address: '0x1::aptos_coin::AptosCoin',
      name: 'Aptos Coin',
      symbol: 'APT',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'APT',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/APT.svg',
      websiteUrl: 'https://aptosfoundation.org',
      category: 'Native',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 1,
      coingeckoId: 'aptos',
      coinMarketCapId: 21794,
    },
    {
      chainId: 1,
      address:
        '0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::USDC',
      name: 'USD Coin',
      symbol: 'USDC',
      decimals: 6,
      bridge: 'LayerZero',
      panoraSymbol: 'lzUSDC',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/USDC.svg',
      websiteUrl: null,
      category: 'Bridged',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 10,
      coingeckoId: 'usd-coin',
      coinMarketCapId: 3408,
    },
    {
      chainId: 1,
      address:
        '0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::USDT',
      name: 'Tether USD',
      symbol: 'USDT',
      decimals: 6,
      bridge: 'LayerZero',
      panoraSymbol: 'lzUSDT',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/USDT.svg',
      websiteUrl: null,
      category: 'Bridged',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 15,
      coingeckoId: 'tether',
      coinMarketCapId: 825,
    },
    {
      chainId: 1,
      address:
        '0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::WETH',
      name: 'Wrapped Ether',
      symbol: 'WETH',
      decimals: 6,
      bridge: 'LayerZero',
      panoraSymbol: 'lzWETH',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/WETH.svg',
      websiteUrl: null,
      category: 'Bridged',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 20,
      coingeckoId: 'ethereum',
      coinMarketCapId: 1027,
    },
    {
      chainId: 1,
      address:
        '0xcfea864b32833f157f042618bd845145256b1bf4c0da34a7013b76e42daa53cc::usdy::USDY',
      name: 'Ondo US Dollar Yield',
      symbol: 'USDY',
      decimals: 6,
      bridge: null,
      panoraSymbol: 'USDY',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/USDY.svg',
      websiteUrl: 'https://ondo.finance',
      category: 'Native',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 25,
      coingeckoId: 'ondo-us-dollar-yield',
      coinMarketCapId: 29256,
    },
    {
      chainId: 1,
      address:
        '0x7fd500c11216f0fe3095d0c4b8aa4d64a4e2e04f83758462f2b127255643615::thl_coin::THL',
      name: 'Thala Token',
      symbol: 'THL',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'THL',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/THL.png',
      websiteUrl: 'https://www.thala.fi',
      category: 'Native',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 30,
      coingeckoId: 'thala',
      coinMarketCapId: 24268,
    },
    {
      chainId: 1,
      address:
        '0x2ebb2ccac5e027a87fa0e2e5f656a3a4238d6a48d93ec9b610d570fc0aa0df12',
      name: 'Cellana',
      symbol: 'CELL',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'CELL',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/CELL.png',
      websiteUrl: 'https://cellana.finance',
      category: 'Native',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 35,
      coingeckoId: 'cellena-finance',
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0xe4ccb6d39136469f376242c31b34d10515c8eaaa38092f804db8e08a8f53c5b2::assets_v1::EchoCoin002',
      name: 'Gui Inu',
      symbol: 'GUI',
      decimals: 6,
      bridge: null,
      panoraSymbol: 'GUI',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/GUI.png',
      websiteUrl: 'https://www.guiinu.com',
      category: 'Meme',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 40,
      coingeckoId: 'gui-inu',
      coinMarketCapId: 28851,
    },
    {
      chainId: 1,
      address:
        '0x111ae3e5bc816a5e63c2da97d0aa3886519e0cd5e4b046659fa35796bd11542a::amapt_token::AmnisApt',
      name: 'Amnis Aptos Coin',
      symbol: 'amAPT',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'amAPT',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/amAPT.png',
      websiteUrl: 'https://amnis.finance',
      category: 'Native',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 45,
      coingeckoId: 'amnis-aptos',
      coinMarketCapId: 29034,
    },
    {
      chainId: 1,
      address:
        '0xfaf4e633ae9eb31366c9ca24214231760926576c7b625313b3688b5e900731f6::staking::ThalaAPT',
      name: 'Thala APT',
      symbol: 'thAPT',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'thAPT',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/thAPT.png',
      websiteUrl: 'https://thala.fi',
      category: 'Native',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 50,
      coingeckoId: 'thala-apt',
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0x111ae3e5bc816a5e63c2da97d0aa3886519e0cd5e4b046659fa35796bd11542a::stapt_token::StakedApt',
      name: 'Staked Aptos Coin',
      symbol: 'stAPT',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'stAPT',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/stAptAmnis.png',
      websiteUrl: 'https://stake.amnis.finance',
      category: 'Native',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 55,
      coingeckoId: 'amnis-staked-aptos-coin',
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0xfaf4e633ae9eb31366c9ca24214231760926576c7b625313b3688b5e900731f6::staking::StakedThalaAPT',
      name: 'Staked Thala APT',
      symbol: 'sthAPT',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'sthAPT',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/sthAPT.png',
      websiteUrl: 'https://thala.fi',
      category: 'Native',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 60,
      coingeckoId: 'staked-thala-apt',
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0x6f986d146e4a90b828d8c12c14b6f4e003fdff11a8eecceceb63744363eaac01::mod_coin::MOD',
      name: 'Move Dollar',
      symbol: 'MOD',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'MOD',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/MOD.png',
      websiteUrl: 'https://www.thala.fi',
      category: 'Native',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 65,
      coingeckoId: 'move-dollar',
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0x5e156f1207d0ebfa19a9eeff00d62a282278fb8719f4fab3a586a0a2c0fffbea::coin::T',
      name: 'USD Coin',
      symbol: 'USDC',
      decimals: 6,
      bridge: 'Wormhole',
      panoraSymbol: 'whUSDC',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/USDC.svg',
      websiteUrl: null,
      category: 'Bridged',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 70,
      coingeckoId: 'usd-coin',
      coinMarketCapId: 3408,
    },
    {
      chainId: 1,
      address:
        '0x73eb84966be67e4697fc5ae75173ca6c35089e802650f75422ab49a8729704ec::coin::DooDoo',
      name: 'DooDoo',
      symbol: 'DooDoo',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'DooDoo',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/DOODOO.png',
      websiteUrl: 'https://doodoo.io',
      category: 'Meme',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 75,
      coingeckoId: 'doodoo',
      coinMarketCapId: 28881,
    },
    {
      chainId: 1,
      address:
        '0x4fbed3f8a3fd8a11081c8b6392152a8b0cb14d70d0414586f0c9b858fcd2d6a7::UPTOS::UPTOS',
      name: 'UPTOS',
      symbol: 'UPTOS',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'UPTOS',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/UPTOS.svg',
      websiteUrl: 'https://uptos.xyz',
      category: 'Meme',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 80,
      coingeckoId: 'uptos',
      coinMarketCapId: 30366,
    },
    {
      chainId: 1,
      address:
        '0x63be1898a424616367e19bbd881f456a78470e123e2770b5b5dcdceb61279c54::movegpt_token::MovegptCoin',
      name: 'MOVEGPT',
      symbol: 'MGPT',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'MGPT',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/MGPT.jpg',
      websiteUrl: 'https://movegpt.io/',
      category: 'Native',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 85,
      coingeckoId: null,
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0x55987edfab9a57f69bac759674f139ae473b5e09a9283848c1f87faf6fc1e789::shrimp::ShrimpCoin',
      name: 'SHRIMP',
      symbol: 'SHRIMP',
      decimals: 2,
      bridge: null,
      panoraSymbol: 'SHRIMP',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/SHRIMP.png',
      websiteUrl: 'https://shrimp.app',
      category: 'Meme',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 90,
      coingeckoId: null,
      coinMarketCapId: 29921,
    },
    {
      chainId: 1,
      address:
        '0x159df6b7689437016108a019fd5bef736bac692b6d4a1f10c941f6fbb9a74ca6::oft::CakeOFT',
      name: 'PancakeSwap Token',
      symbol: 'CAKE',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'CAKE',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/Pancake.png',
      websiteUrl: 'https://pancakeswap.finance',
      category: 'Native',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 95,
      coingeckoId: 'pancakeswap-token',
      coinMarketCapId: 7186,
    },
    {
      chainId: 1,
      address:
        '0xe50684a338db732d8fb8a3ac71c4b8633878bd0193bca5de2ebc852a83b35099::propbase_coin::PROPS',
      name: 'Propbase',
      symbol: 'PROPS',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'PROPS',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/PROPS.png',
      websiteUrl: 'https://www.propbase.app',
      category: 'Native',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 100,
      coingeckoId: 'propbase',
      coinMarketCapId: 28385,
    },
    {
      chainId: 1,
      address:
        '0xbe3c4b493632b4d776d56e19d91dcfb34f591f759f6b57f8632d455360da503c::dumdum_coin::DumdumCoin',
      name: 'Dumdum',
      symbol: 'DUMDUM',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'DUMDUM',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/DUMDUM.png',
      websiteUrl: 'https://dumdum.cc/',
      category: 'Meme',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 105,
      coingeckoId: null,
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0x665d06fcd9c94430099f82973f2a5e5f13142e42fa172e72ce14f51a64bd8ad9::coin_mbx::MBX',
      name: 'MARBLEX',
      symbol: 'MBX',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'MBX',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/MBX.png',
      websiteUrl: 'https://marblex.io',
      category: 'Native',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 110,
      coingeckoId: 'marblex',
      coinMarketCapId: 18895,
    },
    {
      chainId: 1,
      address:
        '0xc26a8eda1c3ab69a157815183ddda88c89d6758ee491dd1647a70af2907ce074::coin::Chewy',
      name: 'Chewy',
      symbol: 'CHEWY',
      decimals: 0,
      bridge: null,
      panoraSymbol: 'CHEWY',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/CHEWY.png',
      websiteUrl: 'https://chewytoken.com',
      category: 'Meme',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 115,
      coingeckoId: null,
      coinMarketCapId: 30840,
    },
    {
      chainId: 1,
      address:
        '0x4c71c33b9ec2f263a7f25779bd9a2531165d4da9a963e042a20f288688094a66::APTO_THE_MASCOT::APTO_THE_MASCOT',
      name: 'Apto The Mascot',
      symbol: 'APTO',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'APTO',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/APTO.png',
      websiteUrl: 'https://aptothemascot.com/',
      category: 'Meme',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 120,
      coingeckoId: null,
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0xf891d2e004973430cc2bbbee69f3d0f4adb9c7ae03137b4579f7bb9979283ee6::APTOS_FOMO::APTOS_FOMO',
      name: 'APTOS FOMO',
      symbol: 'FOMO',
      decimals: 6,
      bridge: null,
      panoraSymbol: 'FOMO',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/FOMO.png',
      websiteUrl: null,
      category: 'Meme',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 125,
      coingeckoId: null,
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0x7b7bab2131de3e4f318b4abaa952f7c817b2c3df16c951caca809ac9ca9b650e::APARTMENT::APARTMENT',
      name: 'APARTMENT',
      symbol: 'APARTMENT',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'APARTMENT',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/APARTMENT.png',
      websiteUrl: 'https://aptosapartments.rent',
      category: 'Meme',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 130,
      coingeckoId: null,
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0xdf3d5eb83df80dfde8ceb1edaa24d8dbc46da6a89ae134a858338e1b86a29e38::coin::Returd',
      name: 'Returd',
      symbol: 'RETuRD',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'RETuRD',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/RETURD.png',
      websiteUrl: null,
      category: 'Meme',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 135,
      coingeckoId: null,
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0x13341e81b2c5960623f31cfee0b1ef674cbf23ca302852159b542adc6afe0f37::TEH_DORK_KNITE::TEH_DORK_KNITE',
      name: 'Teh Dork Knite',
      symbol: 'BAPTMAN',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'BAPTMAN',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/BAPTMAN.jpg',
      websiteUrl: 'https://baptmantoken.com',
      category: 'Meme',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 140,
      coingeckoId: null,
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0xd2c9cfe8dd64ebdf9b39e1525997cef33bd178161c59410097d3f3e0704a5df3::ROO::ROO',
      name: 'ROO',
      symbol: 'ROO',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'ROO',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/ROO.jpg',
      websiteUrl: 'https://aptosroo.io',
      category: 'Meme',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 145,
      coingeckoId: null,
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0x389dbbc6884a1d5b1ab4e1df2913a8c1b01251e50aed377554372b2842c5e3ef::chad_coin::ChadCoin',
      name: 'CHAD',
      symbol: 'CHAD',
      decimals: 6,
      bridge: null,
      panoraSymbol: 'CHAD',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/CHAD.png',
      websiteUrl: null,
      category: 'Meme',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 150,
      coingeckoId: null,
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0x1fc2f33ab6b624e3e632ba861b755fd8e61d2c2e6cf8292e415880b4c198224d::apt20::APTS',
      name: 'APTS',
      symbol: 'APTS',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'APTS',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/APTS.png',
      websiteUrl: null,
      category: 'Meme',
      isInPanoraTokenList: false,
      isBanned: false,
      panoraOrderIndex: 155,
      coingeckoId: null,
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0x4ed27736e724e403f9b4645ffef0ae86fd149503f45b37c428ffabd7e46e5b05::core::RenegadeCoin',
      name: 'RENA',
      symbol: 'RENA',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'RENA',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/RENA.png',
      websiteUrl: 'https://www.renegades.build/',
      category: 'Native',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 160,
      coingeckoId: null,
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0x84edd115c901709ef28f3cb66a82264ba91bfd24789500b6fd34ab9e8888e272::coin::DLC',
      name: 'Doglaika Coin',
      symbol: 'DLC',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'DLC',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/DLC.png',
      websiteUrl: null,
      category: 'Meme',
      isInPanoraTokenList: false,
      isBanned: false,
      panoraOrderIndex: 165,
      coingeckoId: 'doglaikacoin',
      coinMarketCapId: 23200,
    },
    {
      chainId: 1,
      address:
        '0xc82974034820c34f065f948f1bee473d481bf99fde2d23e981043e5038cb36be::WOOF::Woof',
      name: 'Woof',
      symbol: 'Woof',
      decimals: 6,
      bridge: null,
      panoraSymbol: 'Woof',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/WOOF.png',
      websiteUrl: null,
      category: 'Meme',
      isInPanoraTokenList: false,
      isBanned: false,
      panoraOrderIndex: 170,
      coingeckoId: null,
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::WBTC',
      name: 'Wrapped BTC',
      symbol: 'WBTC',
      decimals: 6,
      bridge: 'LayerZero',
      panoraSymbol: 'lzWBTC',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/BTC.webp',
      websiteUrl: null,
      category: 'Bridged',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 175,
      coingeckoId: 'bitcoin',
      coinMarketCapId: 1,
    },
    {
      chainId: 1,
      address:
        '0x5c738a5dfa343bee927c39ebe85b0ceb95fdb5ee5b323c95559614f5a77c47cf::Aptoge::Aptoge',
      name: 'Aptoge',
      symbol: 'APTOGE',
      decimals: 6,
      bridge: null,
      panoraSymbol: 'APTOGE',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/APTOGE.png',
      websiteUrl: 'https://aptoge.com',
      category: 'Meme',
      isInPanoraTokenList: false,
      isBanned: false,
      panoraOrderIndex: 180,
      coingeckoId: null,
      coinMarketCapId: 22383,
    },
    {
      chainId: 1,
      address:
        '0x5c738a5dfa343bee927c39ebe85b0ceb95fdb5ee5b323c95559614f5a77c47cf::AptSwap::AptSwapGovernance',
      name: 'AptSwap Governance Token',
      symbol: 'APTSWAP',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'APTSWAP',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/AptSwap.png',
      websiteUrl: 'http://aptswap.io',
      category: 'Native',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 185,
      coingeckoId: null,
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0x27fafcc4e39daac97556af8a803dbb52bcb03f0821898dc845ac54225b9793eb::move_coin::MoveCoin',
      name: 'BlueMove',
      symbol: 'MOVE',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'MOVE',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/MOVE.svg',
      websiteUrl: 'https://bluemove.net',
      category: 'Native',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 190,
      coingeckoId: 'bluemove',
      coinMarketCapId: 23359,
    },
    {
      chainId: 1,
      address:
        '0x881ac202b1f1e6ad4efcff7a1d0579411533f2502417a19211cfc49751ddb5f4::coin::MOJO',
      name: 'Mojito',
      symbol: 'MOJO',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'MOJO',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/MOJO.svg',
      websiteUrl: 'https://dex.mojito.markets',
      category: 'Native',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 195,
      coingeckoId: 'mojito',
      coinMarketCapId: 22399,
    },
    {
      chainId: 1,
      address:
        '0xd0b4efb4be7c3508d9a26a9b5405cf9f860d0b9e5fe2f498b90e68b8d2cedd3e::aptos_launch_token::AptosLaunchToken',
      name: 'AptosLaunch Token',
      symbol: 'ALT',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'ALT',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/ALT.png',
      websiteUrl: 'https://aptoslaunch.io',
      category: 'Native',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 200,
      coingeckoId: 'aptos-launch-token',
      coinMarketCapId: 22065,
    },
    {
      chainId: 1,
      address:
        '0x7c0322595a73b3fc53bb166f5783470afeb1ed9f46d1176db62139991505dc61::abel_coin::AbelCoin',
      name: 'Abel Coin',
      symbol: 'ABEL',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'ABEL',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/ABEL.svg',
      websiteUrl: 'https://abelfinance.xyz',
      category: 'Native',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 205,
      coingeckoId: 'abel-finance',
      coinMarketCapId: 22959,
    },
    {
      chainId: 1,
      address:
        '0xd11107bdf0d6d7040c6c0bfbdecb6545191fdf13e8d8d259952f53e1713f61b5::staked_coin::StakedAptos',
      name: 'Ditto Staked Aptos',
      symbol: 'stAPT',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'stAPT',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/stAptDitto.png',
      websiteUrl: 'https://www.dittofinance.io',
      category: 'Native',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 210,
      coingeckoId: 'ditto-staked-aptos',
      coinMarketCapId: 22290,
    },
    {
      chainId: 1,
      address:
        '0xa2eda21a58856fda86451436513b867c97eecb4ba099da5775520e0f7492e852::coin::T',
      name: 'Tether USD',
      symbol: 'USDT',
      decimals: 6,
      bridge: 'Wormhole',
      panoraSymbol: 'whUSDT',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/USDT.svg',
      websiteUrl: null,
      category: 'Bridged',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 215,
      coingeckoId: 'tether',
      coinMarketCapId: 825,
    },
    {
      chainId: 1,
      address:
        '0x84d7aeef42d38a5ffc3ccef853e1b82e4958659d16a7de736a29c55fbbeb0114::staked_aptos_coin::StakedAptosCoin',
      name: 'Tortuga Staked Aptos',
      symbol: 'tAPT',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'tAPT',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/tAptTortuga.png',
      websiteUrl: 'https://tortuga.finance',
      category: 'Native',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 220,
      coingeckoId: 'tortuga-staked-aptos',
      coinMarketCapId: 22412,
    },
    {
      chainId: 1,
      address:
        '0x8d87a65ba30e09357fa2edea2c80dbac296e5dec2b18287113500b902942929d::celer_coin_manager::UsdtCoin',
      name: 'Tether USD',
      symbol: 'USDT',
      decimals: 6,
      bridge: 'Celer',
      panoraSymbol: 'ceUSDT',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/USDT.svg',
      websiteUrl: null,
      category: 'Bridged',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 225,
      coingeckoId: 'tether',
      coinMarketCapId: 825,
    },
    {
      chainId: 1,
      address:
        '0xfbab9fb68bd2103925317b6a540baa20087b1e7a7a4eb90badee04abb6b5a16f::blt::Blt',
      name: 'Blocto Token',
      symbol: 'BLT',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'BLT',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/BLT.png',
      websiteUrl: null,
      category: 'Native',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 230,
      coingeckoId: 'blocto-token',
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0xe9c192ff55cffab3963c695cff6dbf9dad6aff2bb5ac19a6415cad26a81860d9::mee_coin::MeeCoin',
      name: 'Meeiro',
      symbol: 'MEE',
      decimals: 6,
      bridge: null,
      panoraSymbol: 'MEE',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/MEE.svg',
      websiteUrl: 'https://meeiro.xyz',
      category: 'Native',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 235,
      coingeckoId: null,
      coinMarketCapId: 22705,
    },
    {
      chainId: 1,
      address:
        '0x52ab49a4039c3d2b0aa6e0a00aaed75dcff72a3120ba3610f62d1d0b6032345a::war_coin::WarCoin',
      name: 'War Coin',
      symbol: 'WAR',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'WAR',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/WAR.png',
      websiteUrl: 'https://werewolfandwitch.xyz',
      category: 'Native',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 240,
      coingeckoId: 'war-coin',
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0x16fe2df00ea7dde4a63409201f7f4e536bde7bb7335526a35d05111e68aa322c::AnimeCoin::ANI',
      name: 'AnimeSwap Coin',
      symbol: 'ANI',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'ANI',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/ANI.png',
      websiteUrl: 'https://animeswap.org',
      category: 'Native',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 245,
      coingeckoId: 'animeswap',
      coinMarketCapId: 22650,
    },
    {
      chainId: 1,
      address:
        '0xc71d94c49826b7d81d740d5bfb80b001a356198ed7b8005ae24ccedff82b299c::bridge::APTS',
      name: 'APTS Token',
      symbol: 'APTS',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'APTS',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/APTS.png',
      websiteUrl: 'https://bluemove.net/collection/apts',
      category: 'Native',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 250,
      coingeckoId: null,
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0x14b0ef0ec69f346bea3dfa0c5a8c3942fb05c08760059948f9f24c02cd0d4fd8::mover_token::Mover',
      name: 'Mover',
      symbol: 'MOVER',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'MOVER',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/MOVER.svg',
      websiteUrl: 'https://mov3r.xyz',
      category: 'Native',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 255,
      coingeckoId: 'mover-xyz',
      coinMarketCapId: 23767,
    },
    {
      chainId: 1,
      address:
        '0x8d87a65ba30e09357fa2edea2c80dbac296e5dec2b18287113500b902942929d::celer_coin_manager::UsdcCoin',
      name: 'USD Coin',
      symbol: 'USDC',
      decimals: 6,
      bridge: 'Celer',
      panoraSymbol: 'ceUSDC',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/USDC.svg',
      websiteUrl: null,
      category: 'Bridged',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 260,
      coingeckoId: 'usd-coin',
      coinMarketCapId: 3408,
    },
    {
      chainId: 1,
      address:
        '0x1000000fa32d122c18a6a31c009ce5e71674f22d06a581bb0a15575e6addadcc::usda::USDA',
      name: 'Argo USD',
      symbol: 'USDA',
      decimals: 6,
      bridge: null,
      panoraSymbol: 'USDA',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/USDA.png',
      websiteUrl: 'https://argo.fi',
      category: 'Native',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 265,
      coingeckoId: null,
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::USDD',
      name: 'Decentralized USD',
      symbol: 'USDD',
      decimals: 6,
      bridge: null,
      panoraSymbol: 'USDD',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/USDD.png',
      websiteUrl: null,
      category: 'Native',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 270,
      coingeckoId: 'usdd',
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0xae478ff7d83ed072dbc5e264250e67ef58f57c99d89b447efd8a0a2e8b2be76e::coin::T',
      name: 'Wrapped BTC',
      symbol: 'WBTC',
      decimals: 8,
      bridge: 'Wormhole',
      panoraSymbol: 'whWBTC',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/BTC.webp',
      websiteUrl: null,
      category: 'Bridged',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 275,
      coingeckoId: 'bitcoin',
      coinMarketCapId: 1,
    },
    {
      chainId: 1,
      address:
        '0x8d87a65ba30e09357fa2edea2c80dbac296e5dec2b18287113500b902942929d::celer_coin_manager::WbtcCoin',
      name: 'Wrapped BTC',
      symbol: 'WBTC',
      decimals: 8,
      bridge: 'Celer',
      panoraSymbol: 'ceWBTC',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/BTC.webp',
      websiteUrl: null,
      category: 'Bridged',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 280,
      coingeckoId: 'bitcoin',
      coinMarketCapId: 1,
    },
    {
      chainId: 1,
      address:
        '0xcc8a89c8dce9693d354449f1f73e60e14e347417854f029db5bc8e7454008abb::coin::T',
      name: 'Wrapped Ether',
      symbol: 'WETH',
      decimals: 8,
      bridge: 'Wormhole',
      panoraSymbol: 'whWETH',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/WETH.svg',
      websiteUrl: null,
      category: 'Bridged',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 285,
      coingeckoId: 'ethereum',
      coinMarketCapId: 1027,
    },
    {
      chainId: 1,
      address:
        '0x8d87a65ba30e09357fa2edea2c80dbac296e5dec2b18287113500b902942929d::celer_coin_manager::WethCoin',
      name: 'Wrapper Ether',
      symbol: 'WETH',
      decimals: 8,
      bridge: 'Celer',
      panoraSymbol: 'ceWETH',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/WETH.svg',
      websiteUrl: null,
      category: 'Bridged',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 290,
      coingeckoId: 'ethereum',
      coinMarketCapId: 1027,
    },
    {
      chainId: 1,
      address:
        '0x8d87a65ba30e09357fa2edea2c80dbac296e5dec2b18287113500b902942929d::celer_coin_manager::DaiCoin',
      name: 'Dai Stablecoin',
      symbol: 'DAI',
      decimals: 8,
      bridge: 'Celer',
      panoraSymbol: 'ceDAI',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/DAI.svg',
      websiteUrl: null,
      category: 'Bridged',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 295,
      coingeckoId: 'dai',
      coinMarketCapId: 4943,
    },
    {
      chainId: 1,
      address:
        '0x6312bc0a484bc4e37013befc9949df2d7c8a78e01c6fe14a34018449d136ba86::coin::T',
      name: 'Wrapped BNB',
      symbol: 'WBNB',
      decimals: 8,
      bridge: 'Wormhole',
      panoraSymbol: 'whWBNB',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/BNB.svg',
      websiteUrl: null,
      category: 'Bridged',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 300,
      coingeckoId: 'binancecoin',
      coinMarketCapId: 1839,
    },
    {
      chainId: 1,
      address:
        '0x8d87a65ba30e09357fa2edea2c80dbac296e5dec2b18287113500b902942929d::celer_coin_manager::BnbCoin',
      name: 'Wrapped BNB',
      symbol: 'WBNB',
      decimals: 8,
      bridge: 'Celer',
      panoraSymbol: 'ceWBNB',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/BNB.svg',
      websiteUrl: null,
      category: 'Bridged',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 305,
      coingeckoId: 'binancecoin',
      coinMarketCapId: 1839,
    },
    {
      chainId: 1,
      address:
        '0xdd89c0e695df0692205912fb69fc290418bed0dbe6e4573d744a6d5e6bab6c13::coin::T',
      name: 'Solana',
      symbol: 'SOL',
      decimals: 8,
      bridge: 'Wormhole',
      panoraSymbol: 'whSOL',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/SOL.svg',
      websiteUrl: null,
      category: 'Bridged',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 310,
      coingeckoId: 'solana',
      coinMarketCapId: 5426,
    },
    {
      chainId: 1,
      address:
        '0x4def3d3dee27308886f0a3611dd161ce34f977a9a5de4e80b237225923492a2a::coin::T',
      name: 'Gari',
      symbol: 'GARI',
      decimals: 8,
      bridge: 'Wormhole',
      panoraSymbol: 'whGARI',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/GARI.svg',
      websiteUrl: 'https://gari.network',
      category: 'Bridged',
      isInPanoraTokenList: true,
      isBanned: false,
      panoraOrderIndex: 315,
      coingeckoId: 'gari-network',
      coinMarketCapId: 12969,
    },
    {
      chainId: 1,
      address:
        '0x5a1e84cdd217034d764abb91bf20aa0536c5a8c900831a37b393fe3af98c3f55::thepeoplecoin::The_People',
      name: 'The People',
      symbol: 'People',
      decimals: 6,
      bridge: null,
      panoraSymbol: 'People',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/PEOPLE.png',
      websiteUrl: 'https://thepeopleapt.xyz',
      category: 'Native',
      isInPanoraTokenList: false,
      isBanned: false,
      panoraOrderIndex: 100000,
      coingeckoId: null,
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0xd0b4efb4be7c3508d9a26a9b5405cf9f860d0b9e5fe2f498b90e68b8d2cedd3e::move_ecosystem_fund::MoveEcosystemFund',
      name: 'Move Ecosystem Fund',
      symbol: 'MOVE',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'MOVE',
      logoURI: null,
      websiteUrl: null,
      category: 'Native',
      isInPanoraTokenList: false,
      isBanned: false,
      panoraOrderIndex: 100000,
      coingeckoId: null,
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0xd6d6372c8bde72a7ab825c00b9edd35e643fb94a61c55d9d94a9db3010098548::USDC::Coin',
      name: 'USD Coin (Multichain)',
      symbol: 'multiUSDC',
      decimals: 6,
      bridge: null,
      panoraSymbol: 'multiUSDC',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/USDC.svg',
      websiteUrl: 'https://multichain.org',
      category: 'Native',
      isInPanoraTokenList: false,
      isBanned: false,
      panoraOrderIndex: 100000,
      coingeckoId: 'usd-coin',
      coinMarketCapId: 3408,
    },
    {
      chainId: 1,
      address:
        '0xc91d826e29a3183eb3b6f6aa3a722089fdffb8e9642b94c5fcd4c48d035c0080::coin::T',
      name: 'USD Coin (Wormhole Solana)',
      symbol: 'USDCso',
      decimals: 6,
      bridge: 'Wormhole',
      panoraSymbol: 'whUSDCso',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/USDC.svg',
      websiteUrl: null,
      category: 'Native',
      isInPanoraTokenList: false,
      isBanned: false,
      panoraOrderIndex: 100000,
      coingeckoId: 'usd-coin',
      coinMarketCapId: 3408,
    },
    {
      chainId: 1,
      address:
        '0x389dbbc6884a1d5b1ab4e1df2913a8c1b01251e50aed377554372b2842c5e3ef::EONcoin::EONCoin',
      name: 'EON',
      symbol: 'EON',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'EON',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/EON.svg',
      websiteUrl: 'https://eonlabz.com',
      category: 'Native',
      isInPanoraTokenList: false,
      isBanned: false,
      panoraOrderIndex: 100000,
      coingeckoId: null,
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0x8d87a65ba30e09357fa2edea2c80dbac296e5dec2b18287113500b902942929d::celer_coin_manager::BusdCoin',
      name: 'Binance USD',
      symbol: 'BUSD',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'ceBUSD',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/BUSD.png',
      websiteUrl: null,
      category: 'Native',
      isInPanoraTokenList: false,
      isBanned: false,
      panoraOrderIndex: 100000,
      coingeckoId: 'binance-usd',
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0x25a64579760a4c64be0d692327786a6375ec80740152851490cfd0b53604cf95::coin::ETERN',
      name: 'Eternal Token',
      symbol: 'ETERN',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'ETERN',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/ETERN.svg',
      websiteUrl: 'https://eternalfinance.io',
      category: 'Native',
      isInPanoraTokenList: false,
      isBanned: false,
      panoraOrderIndex: 100000,
      coingeckoId: null,
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0xd916a950d4c1279df4aa0d6f32011842dc5c633a45c11ac5019232c159d115bb::coin::T',
      name: 'wTBT Pool',
      symbol: 'wTBT',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'wTBT',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/WTBT.svg',
      websiteUrl: 'https://www.tprotocol.io',
      category: 'Native',
      isInPanoraTokenList: false,
      isBanned: false,
      panoraOrderIndex: 100000,
      coingeckoId: 'wtbt',
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0xccc9620d38c4f3991fa68a03ad98ef3735f18d04717cb75d7a1300dd8a7eed75::coin::T',
      name: 'Binance USD',
      symbol: 'BUSD',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'whBUSD',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/BUSD.png',
      websiteUrl: null,
      category: 'Native',
      isInPanoraTokenList: false,
      isBanned: false,
      panoraOrderIndex: 100000,
      coingeckoId: 'binance-usd',
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0x777821c78442e17d82c3d7a371f42de7189e4248e529fe6eee6bca40ddbb::apcoin::ApCoin',
      name: 'APass Coin',
      symbol: 'APC',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'APC',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/APC.svg',
      websiteUrl: 'https://aptpp.com',
      category: 'Native',
      isInPanoraTokenList: false,
      isBanned: false,
      panoraOrderIndex: 100000,
      coingeckoId: null,
      coinMarketCapId: 23226,
    },
    {
      chainId: 1,
      address:
        '0x9a19f4c81f7dc7b8ae6f568d562e6fb056c3894303229c91f73f34c24b0403b0::luffycoin::Luffy',
      name: 'LUFFY',
      symbol: 'LUFFY',
      decimals: 6,
      bridge: null,
      panoraSymbol: 'LUFFY',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/LUFFY.webp',
      websiteUrl: null,
      category: 'Native',
      isInPanoraTokenList: false,
      isBanned: false,
      panoraOrderIndex: 100000,
      coingeckoId: null,
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0xcc78307c77f1c2c0fdfee17269bfca7876a0b35438c3442417480c0d5c370fbc::AptopadCoin::APD',
      name: 'Aptopad Coin',
      symbol: 'APD',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'APD',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/APD.png',
      websiteUrl: 'https://aptopad.io',
      category: 'Native',
      isInPanoraTokenList: false,
      isBanned: false,
      panoraOrderIndex: 100000,
      coingeckoId: 'aptopad',
      coinMarketCapId: 24733,
    },
    {
      chainId: 1,
      address:
        '0xced3e98a47279b4d39a75fa8da867e2e8383d5d8ce7e59b2964a9469616a761b::coin::T',
      name: 'Wrapped SUI',
      symbol: 'WSUI',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'WSUI',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/SUI.svg',
      websiteUrl: null,
      category: 'Native',
      isInPanoraTokenList: false,
      isBanned: false,
      panoraOrderIndex: 100000,
      coingeckoId: null,
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0xe1d39a72bd69bc2ebfe008bb925badb23a32883b077218b9e167f74cf703db1a::uptos::UptosCoin',
      name: 'Uptos',
      symbol: 'UPT',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'UPT',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/UPT.svg',
      websiteUrl: null,
      category: 'Native',
      isInPanoraTokenList: false,
      isBanned: false,
      panoraOrderIndex: 100000,
      coingeckoId: null,
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0x33afc05395020c12a31ada7d7d833f31ae1dd892124edec50297c7d608a6e7bd::NEBULA::NEBULA',
      name: 'Nebula',
      symbol: 'NBUL',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'NBUL',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/NBUL.svg',
      websiteUrl: 'https://nebuladao.space',
      category: 'Native',
      isInPanoraTokenList: false,
      isBanned: false,
      panoraOrderIndex: 100000,
      coingeckoId: null,
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0x9906c12b3b7a12721b9dddf23e6dd5ff5dfc93c5241dada855780758b01fedd3::DOOT_SKELETON::DOOT_SKELETON',
      name: 'DOOT Skeleton',
      symbol: 'DOOT',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'DOOT',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/DOOT.svg',
      websiteUrl: 'https://skeltal.io',
      category: 'Native',
      isInPanoraTokenList: false,
      isBanned: false,
      panoraOrderIndex: 100000,
      coingeckoId: null,
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0x8235f05ea1901e682bc09b3be93eba0727e94c020ccb0e57074843315c675521::BLADEEWIFHAT::BLADEEWIFHAT',
      name: 'bladeewifhat',
      symbol: 'BLADEE',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'BLADEE',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/BLADEE.jpg',
      websiteUrl: null,
      category: 'Native',
      isInPanoraTokenList: false,
      isBanned: false,
      panoraOrderIndex: 100000,
      coingeckoId: null,
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0x3be1b0966a7f400c1ea57e6ddfe5f060282592a1f4df4d45872a7c8bc46b5ba5::zapdos::Zapdos',
      name: 'Zapdos',
      symbol: 'ZAP',
      decimals: 1,
      bridge: null,
      panoraSymbol: 'ZAP',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/ZAP.png',
      websiteUrl: 'https://zapdos.dev',
      category: 'Native',
      isInPanoraTokenList: false,
      isBanned: false,
      panoraOrderIndex: 100000,
      coingeckoId: null,
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0x7bdeaba6f037caf06bb5b2d57df9ee03a07e2a9df45b338ef3deb44d16c01d10::spring_coin::Spring_Coin',
      name: 'SPRING',
      symbol: 'SPRING',
      decimals: 9,
      bridge: null,
      panoraSymbol: 'SPRING',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/SPRING.webp',
      websiteUrl: 'https://springers.co.in',
      category: 'Native',
      isInPanoraTokenList: false,
      isBanned: false,
      panoraOrderIndex: 100000,
      coingeckoId: 'spring',
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0x27975005fd8b836a905dc7f81c51f89e76091a4d0c4d694265f6eae0c05cb400::proton_a5d::PROTON_E54',
      name: 'AlpacaINU Coin',
      symbol: 'ALI',
      decimals: 6,
      bridge: null,
      panoraSymbol: 'ALI',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/ALI.svg',
      websiteUrl: 'https://twitter.com/AlpacaINU',
      category: 'Native',
      isInPanoraTokenList: false,
      isBanned: false,
      panoraOrderIndex: 100000,
      coingeckoId: null,
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0xd11107bdf0d6d7040c6c0bfbdecb6545191fdf13e8d8d259952f53e1713f61b5::ditto_discount_coin::DittoDiscountCoin',
      name: 'Ditto Discount Token',
      symbol: 'DTO',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'DTO',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/DTO.svg',
      websiteUrl: 'https://www.dittofinance.io',
      category: 'Native',
      isInPanoraTokenList: false,
      isBanned: false,
      panoraOrderIndex: 100000,
      coingeckoId: 'ditto-discount-token',
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0xd0b4efb4be7c3508d9a26a9b5405cf9f860d0b9e5fe2f498b90e68b8d2cedd3e::legendary_meme::LegendaryMEME',
      name: 'Legendary Meme',
      symbol: 'LME',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'LME',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/LME.svg',
      websiteUrl: 'https://legendaryme.me',
      category: 'Meme',
      isInPanoraTokenList: false,
      isBanned: false,
      panoraOrderIndex: 100000,
      coingeckoId: 'legendary-meme',
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0x66398cf97d29fd3825f65b37cb2773268e5438d37e20777e6a98261da0cf1f1e::ddos_coin::DDOS_COIN',
      name: 'DDOS Token',
      symbol: 'DDOS',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'DDOS',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/DDOS.svg',
      websiteUrl: 'https://ddosapt.com',
      category: 'Meme',
      isInPanoraTokenList: false,
      isBanned: false,
      panoraOrderIndex: 100000,
      coingeckoId: null,
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0x407a220699982ebb514568d007938d2447d33667e4418372ffec1ddb24491b6c::coin::T',
      name: 'Dai Stablecoin (Wormhole)',
      symbol: 'DAI',
      decimals: 8,
      bridge: 'Wormhole',
      panoraSymbol: 'whDAI',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/DAI.svg',
      websiteUrl: null,
      category: 'Native',
      isInPanoraTokenList: false,
      isBanned: false,
      panoraOrderIndex: 100000,
      coingeckoId: 'dai',
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0xacd014e8bdf395fa8497b6d585b164547a9d45269377bdf67c96c541b7fec9ed::coin::T',
      name: 'Tether USD',
      symbol: 'USDTbs',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'USDTbs',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/USDT.svg',
      websiteUrl: null,
      category: 'Native',
      isInPanoraTokenList: false,
      isBanned: false,
      panoraOrderIndex: 100000,
      coingeckoId: 'tether',
      coinMarketCapId: 825,
    },
    {
      chainId: 1,
      address:
        '0x79a6ed7a0607fdad2d18d67d1a0e552d4b09ebce5951f1e5c851732c02437595::coin::T',
      name: 'USD Coin (Wormhole, BSC)',
      symbol: 'USDCbs',
      decimals: 8,
      bridge: 'Wormhole',
      panoraSymbol: 'whUSDCbs',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/USDC.svg',
      websiteUrl: null,
      category: 'Native',
      isInPanoraTokenList: false,
      isBanned: false,
      panoraOrderIndex: 100000,
      coingeckoId: 'usd-coin',
      coinMarketCapId: 3408,
    },
    {
      chainId: 1,
      address:
        '0x39d84c2af3b0c9895b45d4da098049e382c451ba63bec0ce0396ff7af4bb5dff::coin::T',
      name: 'USD Coin (Wormhole Avalanche)',
      symbol: 'USDCav',
      decimals: 6,
      bridge: 'Wormhole',
      panoraSymbol: 'whUSDCav',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/USDC.svg',
      websiteUrl: null,
      category: 'Native',
      isInPanoraTokenList: false,
      isBanned: false,
      panoraOrderIndex: 100000,
      coingeckoId: 'usd-coin',
      coinMarketCapId: 3408,
    },
    {
      chainId: 1,
      address:
        '0x5b1bbc25524d41b17a95dac402cf2f584f56400bf5cc06b53c36b331b1ec6e8f::coin::T',
      name: 'Wrapped AVAX (Wormhole)',
      symbol: 'WAVAX',
      decimals: 8,
      bridge: 'Wormhole',
      panoraSymbol: 'whWAVAX',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/AVAX.webp',
      websiteUrl: null,
      category: 'Native',
      isInPanoraTokenList: false,
      isBanned: false,
      panoraOrderIndex: 100000,
      coingeckoId: 'avalanche-2',
      coinMarketCapId: 5805,
    },
    {
      chainId: 1,
      address:
        '0x397071c01929cc6672a17f130bd62b1bce224309029837ce4f18214cc83ce2a7::USDC::USDC',
      name: '💸 USDC-APTOS.ORG',
      symbol: 'USDC-APTOS',
      decimals: 6,
      bridge: null,
      panoraSymbol: 'USDC-APTOS',
      logoURI: null,
      websiteUrl: null,
      category: 'Native',
      isInPanoraTokenList: false,
      isBanned: true,
      panoraOrderIndex: 100000,
      coingeckoId: 'usd-coin',
      coinMarketCapId: 3408,
    },
    {
      chainId: 1,
      address:
        '0xbbc4a9af0e7fa8885bda5db08028e7b882f2c2bba1e0fedbad1d8316f73f8b2f::memes::Memecoins',
      name: '⭐ aptosmeme.com',
      symbol: 'Meme',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'Meme',
      logoURI: null,
      websiteUrl: null,
      category: 'Native',
      isInPanoraTokenList: false,
      isBanned: true,
      panoraOrderIndex: 100000,
      coingeckoId: null,
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0xf658475dc67a4d48295dbcea6de1dc3c9af64c1c80d4161284df369be941dafb::moon_coin::MoonCoin',
      name: 'ClaimAPTGift.com',
      symbol: 'aGift.site',
      decimals: 6,
      bridge: null,
      panoraSymbol: 'aGift.site',
      logoURI: null,
      websiteUrl: null,
      category: 'Native',
      isInPanoraTokenList: false,
      isBanned: true,
      panoraOrderIndex: 100000,
      coingeckoId: null,
      coinMarketCapId: null,
    },
    {
      chainId: 1,
      address:
        '0x83b619e2d9e6e10d15ed4b714111a4cd9526c1c2ae0eec4b252a619d3e8bdda3::MAU::MAU',
      name: 'MAU',
      symbol: 'MAU',
      decimals: 8,
      bridge: null,
      panoraSymbol: 'MAU',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/MAU.png',
      websiteUrl: 'https://mauprotocol.com',
      category: 'Native',
      isInPanoraTokenList: false,
      isBanned: true,
      panoraOrderIndex: 100000,
      coingeckoId: null,
      coinMarketCapId: null,
    },
  ],
} as const
